import { template as template_bb47014bb84d4e78bf9945e68dbe0b1f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_bb47014bb84d4e78bf9945e68dbe0b1f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
