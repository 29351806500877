import { template as template_b0d52c714a5642789b54269fc9f47541 } from "@ember/template-compiler";
const FKText = template_b0d52c714a5642789b54269fc9f47541(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
