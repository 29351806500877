import { template as template_b796f5351b5c41eb907b66410819a1a1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b796f5351b5c41eb907b66410819a1a1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
