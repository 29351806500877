import { template as template_2f4d47ffb69243648e8da1d89de9c89c } from "@ember/template-compiler";
const SidebarSectionMessage = template_2f4d47ffb69243648e8da1d89de9c89c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
